<template>
	<div>
		<!-- <s-select-definition
			:def="1405"
			@getDataInitial="getStatesKamba($event)"
			hidden
		></s-select-definition> -->
		<v-card
			outlined
		>
			<v-card-title>
				<v-row>
					<v-col>
						{{ "Mis tareas" }}
						<v-btn
							style="margin-left: 5px;"
							fab
							x-small
							color="success"
							@click="openDialogTicket()"
						>
							<v-icon>{{ 'mdi mdi-plus' }}</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col
						v-for="item in stages"
						:key="item.DedValue"
					>
						<v-card>
							<v-card-title :color="item.DedValue">
								<h3>
									<v-chip
										outlined
										:color="item.DedHelper"
									>
										{{  item.DedDescription }}
									</v-chip>
								</h3>
								<!-- item.DedDescription -->
							</v-card-title>
							<v-divider></v-divider>
							<v-card-text>
								<Container
									group-name="trello"
									@drag-start="handleDragStart(item.DedDescription, $event, item.DedValue)"
									@drop="handleDrop(item.DedDescription.toString(), $event, item.DedValue)"
									:get-child-payload="getChildPayload"
								>
									<Draggable
										v-for="chil in item.child"
										:key="chil.TckID"
									>
										<v-card dense outlined>
											<v-card-title>
												<!-- boton para autoasignarme como responsable -->
												<v-btn
													v-if="chil.TypeState == 1"
													style="margin-left: 10px;"
													fab
													x-small
													color="primary"
													@click="autoAsignAgent(chil)"
												>
													<v-icon>mdi-account-check</v-icon>
												</v-btn>

												<!-- boton para transferir el ticket -->
												<v-btn
													v-if="chil.TypeState != 1"
													style="margin-left: 10px;"
													fab
													x-small
													color="success"
													@click="transferTicket(chil)"
												>
													<v-icon>mdi-account-switch</v-icon>
												</v-btn>

												<v-chip outlined>
													<b>{{ chil.TckTitle }}</b>
												</v-chip>
												<v-spacer></v-spacer>
												<v-btn
													v-if="chil.TypeState == 1"
													style="margin-left: 10px;"
													fab
													x-small
													color="error"
													@click="deleteTicket(chil)"
												>
													<i class="fas fa-trash-alt"></i>
												</v-btn>
												<!-- <v-btn
													v-if="chil.TypeState == 4"
													style="margin-left: 10px;"
													fab
													x-small
													color="success"
													@click="deleteTicket(chil)"
												>
													<v-icon>mdi-checkbox-marked-circle-outline</v-icon>
												</v-btn> -->
											</v-card-title>
											<v-card-text>
												{{ chil.TckDescription }}
											</v-card-text>
										</v-card>
									</Draggable>
								</Container>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- agregar una tarea -->
		<v-dialog
			v-if="dialogTicket"
			v-model="dialogTicket"
			width="500"
		>
			<create-ticket
				@closeDialogTicket="closeDialogTicket()"
			></create-ticket>
		</v-dialog>

		<!-- transferir una tarea -->
		<v-dialog
			v-if="dialogTransfer"
			v-model="dialogTransfer"
			width="500"
		>
			<v-card>
				<s-toolbar
					label="Transferir"
					close
					@close="closeDialogTransfer()"
					save
				></s-toolbar>
				<v-card-text>
					<v-row>
						<v-col class="s-col-form">
							<s-select
								label="Agente"
								item-value="AgeID"
								item-text="NtpFullName"
								:items="agents"
								v-model="transferObj.AgeID"
							></s-select>
						</v-col>
					</v-row>
					<v-row>
						<v-col  class="s-col-form">
							<s-textarea
								label="Motivo de transferencia"
								v-model="transferObj.reason"
							></s-textarea>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>

	</div>
</template>

<script>
	import _sTicketService from "@/services/HelpDesk/HdkTicketService.js";
	import _sAgentService from '@/services/HelpDesk/HdkAgentService.js';

	import CreateTicket from "./CreateTicket"
	import {Container, Draggable} from "vue-smooth-dnd";

	export default {
		components: {
			CreateTicket,
			Container,
			Draggable
		},

		data() {
			return {
				stages: [],
				dialogTicket : false,
				dragginCard : {
					lane: '',
					index : -1,
					cardData : {}
				},
				userInfo : {},
				dialogTransfer : false,
				agents: [],
				transferObj : {}
			}
		},

		methods: {
			getStatesKamba(items) {
				console.log('ddddddddd ', this.$fun.getUserInfo().PrsID);
				_sTicketService
				.liststate({PrsID : this.$fun.getUserInfo().PrsID}, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.stages = resp.data;
					}
				})
			},

			openDialogTicket()
			{
				this.dialogTicket = true;
			},

			closeDialogTicket()
			{
				this.dialogTicket = false;
				this.getStatesKamba();
			},

			/* retorna la posicion del card que estas moviendo */
			getChildPayload(index)
			{
				return { index, };
			},

			handleDragStart(lane, dragResult, DedValue) 
			{
				const { payload, isSource } = dragResult;
				
				if(isSource)
				{
					let cardSelected = this.stages.find(element =>{
						return element.DedDescription == lane
					});
					console.log('cardSelected ', cardSelected);
					
					this.dragginCard = {
						DedValue : DedValue,
						lane ,
						index : payload.index,
						cardData : {
							...cardSelected.child[payload.index]
						}
					};
				}
			},

			handleDrop(lane, dropResult, TckID)
			{
				const { removedIndex, addedIndex } = dropResult;

				let cardSelectedDestination = this.stages.find(element =>{
					return element.DedDescription == lane
				});

				if(lane == this.dragginCard.lane && removedIndex == addedIndex)
				{
					return;
				}

				if(removedIndex != null)
				{
					cardSelectedDestination.child.splice(removedIndex, 1);
				}

				if(addedIndex != null)
				{
					this.saveChangeStateTicket(cardSelectedDestination);
					cardSelectedDestination.child.splice(addedIndex, 0, this.dragginCard.cardData);
				}
			},

			deleteTicket(chil)
			{
				this.$fun.alert("¿Seguro de eliminar?", "question")
				.then(r => {
					if(r.value)
					{
						chil.SecStatus = 0;
						chil.UsrUpdateID = this.$fun.getUserID()
						_sTicketService
						.save(chil, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Eliminado correctamente", "success");
								this.getStatesKamba();
							}
						})
					}
				})
				
			},

			saveChangeStateTicket(cardSelectedDestination)
			{
				let ticket =  this.dragginCard.cardData;
				ticket.TypeState = cardSelectedDestination.DedValue;
				ticket.UsrUpdateID = this.$fun.getUserID();
				
				_sTicketService
				.save(ticket, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.$fun.alert("Cambiado correctamente", "success");
						this.getStatesKamba();
					}
				});
			},

			autoAsignAgent(ticket)
			{
				this.$fun.alert("¿Seguro de auto asignar?", "question")
				.then(r => {
					if(r.value)
					{
						ticket.AgeID = this.userInfo.PrsID;
						ticket.UsrUpdateID = this.$fun.getUserID();
						ticket.TypeState = 2;
						ticket.TckStatus = 100;

						_sTicketService
						.save(ticket, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Asignado Correctamente", "success");
								this.getStatesKamba();
							}
						});
					}
				})
			},

			transferTicket(ticket)
			{
				this.dialogTransfer = true;
				this.getListAgente();
				console.log('ticket ', ticket);
				/* this.$fun.alert("¿Seguro de transferir?", "question")
				.then(r => {
					if(r.value)
					{
						this.dialogTransfer = true;
						this.getListAgente();
						this.$fun.alert("Transferido correctamente al agente :", "success");
					}
				}) */
			},

			closeDialogTransfer()
			{
				this.dialogTransfer = false;
				this.getStatesKamba();
			},

			getListAgente()
			{
				_sAgentService
				.list({}, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.agents = resp.data;
					}
				})
			}
		},

		created () {
			this.getStatesKamba();
			this.userInfo = this.$fun.getUserInfo();
			console.log('userInfo ', this.userInfo);
		},
		
	}
</script>