import Service from "../Service";

const resource = "ticket/";


export default {
    save(ticket, requestID) {
        return Service.post(resource + "save", ticket, {
            params: { requestID: requestID },
        });
    },

	list(parameters, requestID) {
        return Service.post(resource + "list", parameters, {
            params: { requestID: requestID },
        });
    },

	liststate(params, requestID) {
        return Service.post(resource + "liststate", params, {
            params: { requestID: requestID },
        });
    },

};