<template>
	<v-card>
		<s-toolbar
			label="Crear Ticket"
			save
			@save="saveTicket()"
			close
			@close="closeDialogTicket()"
		></s-toolbar>
		<v-card-text>
			<v-col>
				<v-row>
					<v-col class="s-col-form">
						<v-checkbox
							label="Tarea Programada"
							hide-details=""
							v-model="ticket.TypeTask"
						></v-checkbox>
					</v-col>
					<v-col class="s-col-form">
						<v-checkbox
							label="Asignar a un agente"
							hide-details=""
							v-model="externalAgent"
						></v-checkbox>
					</v-col>
				</v-row>
				<v-row v-if="externalAgent">
					<v-col class="s-col-form">
						<s-select
							label="Agente"
							item-value="AgeID"
							item-text="NtpFullName"
							:items="agents"
							v-model="ticket.AgeID"
						></s-select>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="s-col-form">
						<s-select-area
							label="Area"
							v-model="ticket.AreID"
						></s-select-area>
					</v-col>
					<v-col class="s-col-form">
						<s-toolbar-person
							label="Encargado"
							noEdit
							@returnPerson="hadReturnPerson($event)"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="s-col-form">
						<s-text
							label="Usuario"
							v-model="NtpFullName"
						></s-text>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="s-col-form">
						<s-text
							label="Titulo"
							v-model="ticket.TckTitle"
						></s-text>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="s-col-form">
						<s-textarea
							label="Detalles"
							v-model="ticket.TckDescription"
						></s-textarea>
					</v-col>
					<!-- <v-col lg="10" md="10" sm="12" xs="12" class="s-col-form">
						<s-text
							label="Descripción"
							v-model="TsdDescription"
							@keyupEnter="addItemDetail()"
							ref="refTsdDescription"
						></s-text>
					</v-col> -->
					<!-- <v-col lg="2" md="2" sm="12" xs="12">
						<v-btn
							style="margin-left: 5px;"
							fab
							x-small
							color="success"
							@click="addItemDetail()"
						>
							<v-icon>{{ 'mdi mdi-plus' }}</v-icon>
						</v-btn>
					</v-col> -->
				</v-row>
				<!-- <v-row>
					<v-col  class="s-col-form">
						<v-data-table
							dense
							:items="itemsDetail"
							:headers="headers"
						>
							<template v-slot:item.action="{item}">
								<v-btn
									x-small
									color="error"
								>
									<i class="fas fa-trash-alt"></i>
								</v-btn>
							</template>
						</v-data-table>
					</v-col>
				</v-row> -->
			</v-col>
		</v-card-text>
	</v-card>
</template>

<script>
	import _sTicketService from "@/services/HelpDesk/HdkTicketService.js";
	import _sAgentService from '@/services/HelpDesk/HdkAgentService.js';

	import SSelectArea from '@/components/Utils/SSelectArea.vue';
	import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";


	export default {
		components: { 
			SSelectArea,
			sToolbarPerson
		},

		data() {
			return {
				ticket: {},
				NtpFullName : "",
				TsdDescription : "",
				itemsDetail: [],
				headers : [
					{text: "Descripción", value : "TsdDescription"},
					{text: "Acción", value : "action"},
				],
				itemDetail: {},
				externalAgent : 0,
				agents: []
			}
		},

		methods: {
			closeDialogTicket() {
				this.$emit("closeDialogTicket");
			},

			hadReturnPerson(value) {
				if(value != null)
				{
					this.NtpFullName = value.NtpFullName;
					this.worker = value;
				}
			},

			addItemDetail()
			{
				let detail = {
					TsdDescription : this.TsdDescription,
					SecStatus : 1,
					UsrCreateID : this.$fun.getUserID(),
					UsrUpdateID : this.$fun.getUserID()
				}
				this.itemsDetail.push(detail);
				this.TsdDescription = "";
				this.$refs.TsdDescription.focus();
			},

			deleteItemDetail()
			{

			},

			saveTicket()
			{
				this.$fun.alert("¿Seguro de guardar?", "question")
				.then(r => {
					if(r.value)
					{
						
						this.ticket.TypeState = 1;
						this.ticket.PrsID = this.worker.PrsID;
						this.ticket.NtpID = this.worker.NtpID;
						this.ticket.PrsDocumentNumber = this.worker.PrsDocumentNumber;
						this.ticket.SecStatus = 1;
						this.ticket.UsrCreateID = this.$fun.getUserID();
						this.ticket.UsrUpdateID = this.$fun.getUserID();
						if(this.externalAgent)
						{
							this.ticket.TypeState = 2;
						}
						else
						{
							this.ticket.AgeID = 0;
						}

						_sTicketService
						.save(this.ticket, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Guardado correctamente", "success");
								this.closeDialogTicket();
							}
						})
					}
				})
				
			},

			keyupEnter()
			{
				console.log('click enter');
			},

			getListAgente()
			{
				_sAgentService
				.list({}, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.agents = resp.data;
					}
				})
			}
		},

		created () {
			this.getListAgente();
		},
	}
</script>
